<template>
    <a-spin :spinning="spinning">
        <!-- <page-header title="接口镜像管理"></page-header> -->
            <!-- <react-table
                :actions-type="actionsType"
                :showTotal="showTotalFun"
                :table-data="tableData"
                :tableKey="tableKey"
                :total-items="totalItems"
                :columns="columns"
                :hide-row-selection="true"
                :is-full="true"
                :current-page="pageNumber"
                @emitDeleteItem="deleteItem"
                @emitEdit="openForm"
                @emitFieldManage="fieldManage"
                @emitOnPageChange="updatePageChange"
                @emitRefreshData="getData"
                @emitSelectChange="updateSelectChange"
                @emitShowSizeChange="updateShowSizeChange"
                @emitSortData="updateSortData"
            > -->
            <ele-table
                :columns="columns"
                :table-key="tableKey"
                :table-data="tableData"
                :total-items="totalItems"
                :actions-type="actionsType"
                :is-full="true"
                :current-page="pageNumber"
                :hide-row-selection="true"
                @emitSelectChange="updateSelectChange"
                @emitOnPageChange="updatePageChange"
                @emitShowSizeChange="updateShowSizeChange"
            >
                <a-row :gutter="8">
                    <a-col :span="6" class="gutter-row">
                        <a-input
                            :placeholder="l('SearchWithThreeDot')"
                            v-model.trim="filterText"
                        />
                    </a-col>
                    <a-col :span="4" class="gutter-row">
                        <a-button @click="search" type="primary" v-if="isGranted('Pages.SysInterface.Query')">
                            {{ l("Search") }}
                        </a-button>
                        <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                    </a-col>

                    <a-col :span="14" class="gutter-row" style="text-align: right;">
                        <a-button
                            @click="openForm('')"
                            type="primary"
                            v-if="isGranted('Pages.SysInterface.Create')"
                        >
                            <a-icon type="plus"/>
                            {{ l("Create") }}
                        </a-button>
                    </a-col>
                </a-row>
            </ele-table>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalHelper} from "@/shared/helpers";
    import  sysInterface from  "@/shared/service-proxies/sysInterface/services/sys-interface-proxies";
    import EleTable from "@/components/ele-table";

    //import {CollectionFormServiceProxy} from "./services/collectionform-proxies";
    //import {CommonServiceProxy} from "@/shared/common-service";
    import form from "./form.vue";
    import objectHelper  from "../../../shared/helpers/objectHelper";

    import moment from "moment";
    import {ListTable} from "@/components";

    export default {
        mixins: [AppComponentBase],
        name: "sys-interface-index",
        components: {EleTable},
        data() {
            return {
                fieldMap: {
                    'value': 'id',
                    'label':'itemName',
                    'title':'itemName'
                },
                curDataItemTree: [],
                queryModel:{
                    parentId:'',
                    name:''
                },
                columns: [
                    {
                        title: '名称',
                        dataIndex: "name",
                        sorter: true,
                        align: "center",
                       // scopedSlots: { customRender: "taskName" },
                    },
                    {
                        title: '类型',
                        dataIndex: "type",
                        sorter: true,
                        align: "type",
                       //  scopedSlots: { customRender: "type" },
                    },
                    {

                        title: '请求类型',
                        dataIndex: "method",
                        sorter: true,
                        align: "method",

                    },
                    {
                        title: '路径',
                        dataIndex: "path",
                        sorter: true,
                        align: "center",
                        // customRender: function (text, record, index) {
                        //     if (text) {
                        //         switch (text) {
                        //             case 0:
                        //                 return "普通";
                        //                 break;
                        //             case 1:
                        //                 return "重要";
                        //                 break;
                        //             case 2:
                        //                 return "紧急";
                        //                 break;
                        //             default:
                        //                 return "普通";
                        //                 break;
                        //         }
                        //     }
                        //     return "";
                        // },
                        // scopedSlots: { customRender: "level" },
                    },
                    {
                        title: '备注',
                        dataIndex: "desc",
                        sorter: true,
                        align: "desc",
                       // fixed: "right",
                    },
                ],
                spinning: false,
                // 搜索
                filterText: "",
                // 选择多少项
                selectedRowKeys: [],
                tableData: [],
                tableKey: "SysInterfacesPagedDto",
                // 总数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40"],
                request: {
                    sorting: "",
                    maxResultCount: 50,
                    skipCount: 0,
                },
                _serviceProxy: "",
                commonService: null,
                actionsType: {},
            };
        },
        created() {
            let obj = {
                f1:{
                    f1_1:"1"
                },
                f2:[
                    {
                        f2_1:2,
                    }
                ]
            }
            this.initActionsType();
            this.getData();
        },
        methods: {
            search(){
                this.pageNumber = 1
                this.request.skipCount = 0
                this.getData()
            },
            /**
             * 获取数据
             */
            async getData() {
                this.spinning = true;
                 sysInterface.getPagedList({
                    filterText: this.filterText,
                    sorting: this.request.sorting,
                    maxResultCount: this.request.maxResultCount,
                    skipCount: this.request.skipCount
                }).then((res)=>{
                     this.spinning = false;
                     this.tableData = res.items;
                     this.totalItems = res.totalCount;
                     console.log(res)
                     console.log(this.request.maxResultCount)
                     this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
                     console.log(this.totalPages)
                     this.pagerange = [
                         (this.pageNumber - 1) * this.request.maxResultCount + 1,
                         this.pageNumber * this.request.maxResultCount,
                     ];
                     console.log(  this.pagerange )
                 }).catch((data,error)=>{
                     this.spinning = false;
                 })
                 return
        },
            /**
             * table选择事件
             */
            onSelectChange(selectedRowKeys, selectedRows) {
                this.selectedRowKeys = selectedRowKeys;
                this.selectedRows = selectedRows;
                console.log(this.selectedRows);
            },
            /**
             * 分页事件
             */
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            /**
             * table选择事件
             */

            onSelectChange(selectedRowKeys, selectedRows) {
                this.selectedRowKeys = selectedRowKeys;
                this.selectedRows = selectedRows;
                console.log(this.selectedRows);
            },
            /**
             * 分页
             */
            onChange(page, pageSize) {
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            showSizeChange(current, size) {
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            /**
             * 重置
             */
            refreshGoFirstPage() {
                this.filterText = "";
                this.request.skipCount = 0;
                this.getData();
            },
            /**
             * 新建修改
             */
            openForm(param) {
                console.log('openForm!!')
                let mode = 'create'
                console.log(param)
                if (param!=''){
                    mode = 'edit'
                    let  paramData = objectHelper.deepClone(param)
                    paramData.params = JSON.parse(paramData.params )
                    paramData.headers = JSON.parse(paramData.headers )
                    paramData.fields = JSON.parse(paramData.fields )
                    param  = paramData
                    console.log(param)
                }else{
                    param={
                            params: [{name:'simple',value:'simple',paramType:"constant",type:'string'}],
                            fields: [{name:'simple',value:'simple',paramType:"constant",type:'string'}],
                            headers: [{name:'simple',value:'simple',paramType:"constant",type:'string'}],
                            type: 'internal',
                            method: 'GET',
                    }
                }
                ModalHelper.create(
                    form,
                    {
                        mode:mode,
                        formModel:{
                           ...param
                        },
                    },
                    {
                        width: "1200px",
                        isChange:true
                    }
                ).subscribe((res) => {
                    console.log('messageRevecived')
                    console.log(res)
                        this.refreshGoFirstPage();
                });
            },

            /**
             * 清空
             */
            restCheckStatus() {
                this.selectedRowKeys = [];
            },
            /**
             * 单个删除
             */
            deleteItem(item) {
                console.log(item);
                console.log(item.item.id);
                let obj = {};
                obj.id = item.item.id;
                const body = JSON.stringify(obj);
                this.spinning = true;
                this._serviceProxy
                    .changeStatus(body)
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then(() => {
                        this.refreshGoFirstPage();
                        this.$notification["success"]({
                            message: this.l("SuccessfullyDeleted"),
                        });
                    });
            },
            /**
             * 导出为excel
             */
            exportToExcel() {
                this.spinning = true;
                this._serviceProxy.getToExcelFile().then((result) => {
                    this.spinning = false;
                    fileDownloadService.downloadTempFile(result);
                });
            },
            /*
             * 新建文章
             */

            /**
             * emit新建修改
             */
            edit(newV) {
                console.log(newV);
                let {id} = newV;
                this.openForm(id);
            },
            //模板字段管理
            fieldManage(newV) {
                console.log(newV);
                let {id} = newV;
                this.fieldManageForm(id);
            },
            fieldManageForm(id) {
                ModalHelper.create(
                    FieldManageForm,
                    {
                        id: id,
                    },
                    {
                        width: "400px",
                    }
                ).subscribe((res) => {
                    if (res) {
                        this.refreshGoFirstPage();
                    }
                });
            },
            /**
             * 排序更新data
             */
            updateSortData(newV) {
                let {columnKey, order} = newV;
                this.request.sorting = order ? `${columnKey} ${order}` : "";
                this.getData();
            },
            /**
             * 分页
             */
            updatePageChange(newV) {
                let {page, pageSize} = newV;
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            updateShowSizeChange(newV) {
                let {current, size} = newV;
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            /**
             * table选择事件
             */
            updateSelectChange(newV) {
                console.log(newV);
                let {selectedRowKeys, selectedRows} = newV;
                this.selectedRowKeys = selectedRowKeys;
            },

            /**
             * 初始化需要显示的func btn
             */
            initActionsType() {
                let _that = this
                let obj = {
                    type: "sys-interface-action",
                    isShow: true,
                    fns: {
                        dropList:[{
                            granted: this.isGranted('Pages.SysInterface.Edit'), name: this.l("修改"), icon: "profile", fn: (data) => {
                                _that.openForm(data)
                            }
                        }],
                        //check: { granted: true, name: this.l("查看") },
                        edit: { isShow:true,granted: true, name: this.l("Edit"),fn:()=>{
                                console.log('edit')
                            }},
                        //create: { granted: true, name: "新建" },
                        //delete: { granted: true, name: this.l("Delete") },
                    },
                };
                this.actionsType = obj;
            },
        },
    };
</script>

<style lang="less" scoped>
    .btn--container {
        margin-bottom: 20px;
    }

    .pagination {
        margin: 10px auto;
        text-align: right;
    }

    .table-plus,
    .table-delete {
        margin-left: 10px;
    }
</style>
