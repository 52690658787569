<template>
    <a-spin :spinning="spinning">
        <div class="modal-header" style="margin-bottom: 10px">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span span v-if="formModel.id"
                >{{ mode === "edit" ? "修改" : "" }}{{ formModel.name }}</span
                >
                <span span v-if="!formModel.id">创建接口镜像</span>
            </div>
        </div>

        <a-form-model :rules="validModel" ref="ruleForm" :layout="form.layout" :model="formModel"
                      v-bind="formItemLayout">
            <a-tabs>

                <a-tab-pane key="1">
                <span slot="tab">
                    基本信息
                </span>
                    <a-form-model-item label="接口镜像名称" prop="name">
                        <a-input placeholder="请输入接口镜像名称" v-model="formModel.name"></a-input>
                    </a-form-model-item>

                    <a-form-model-item label="接口类型" prop="type">
                        <a-select v-model="formModel.type" :options="options('interface.type')"></a-select>
                    </a-form-model-item>

                    <a-form-model-item label="请求方法" prop="method">
                        <a-select v-model="formModel.method" :options="options('interface.method')"></a-select>
                    </a-form-model-item>

                    <a-form-model-item label="内部接口" prop="path" v-if="formModel.type == 'internal'">
                        <a-tree-select
                                v-model="formModel.path"
                                show-search
                                style="width: 100%"
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                placeholder="请选择内部接口"
                                allow-clear
                                tree-default-expand-all
                        >
                            <a-tree-select-node v-for="(item,i) in internalServices" :selectable="false" :key="i"
                                                :value="item.path" :title="item.name">
                                <a-tree-select-node v-for="interfaceInfo in item.interfacesInfos" :isLeaf="true"
                                                    :selectable="true" :key="interfaceInfo.path"
                                                    :value="interfaceInfo.path" :title="interfaceInfo.name">
                                    {{interfaceInfo.name}}
                                </a-tree-select-node>
                            </a-tree-select-node>
                        </a-tree-select>

                    </a-form-model-item>

                    <a-form-model-item label="接口地址" prop="path">
                        <a-input placeholder="请输入外部接口地址" v-model="formModel.path"></a-input>
                    </a-form-model-item>
                </a-tab-pane>

                <a-tab-pane key="2">

                  <span slot="tab">
                    请求头部
                </span>
                    <a-alert style="margin-bottom:10px" message="对于内部接口，由于客户端发构造的请求已经包含常驻headers不需要另外填写" type="info"
                             close-text="好的"/>
                    <a-row>
                        <a-col :span="24">
                            <a-button @click="addItem" data-target="headers" type="primary">+</a-button>
                        </a-col>
                    </a-row>
                    <a-row v-for="(item,i) in formModel.headers">
                        <a-col :span="6">
                            <a-form-model-item :label="'参数名称'" :labelCol="labelCol">
                                <a-input placeholder="参数名称" v-model="item.name"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="5">
                            <a-form-model-item label="值类型" :labelCol="labelCol">
                                <a-select v-model="item.paramType"  defaultVlaue="constant" :options="options('paramType')"></a-select>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="6" v-if="item.paramType =='dynamic'">
                            <a-form-model-item :labelCol="labelCol" label="表达式" >
                                <a-input placeholder="请输入表达式" v-model="item.path"></a-input>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="6"  v-if="item.paramType =='constant'" >
                            <a-form-model-item :labelCol="labelCol" label="常量">
                                <a-input placeholder="值" v-model="item.value"></a-input>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="6">
                            <a-form-model-item :labelCol="labelCol" :label="'参数类型'">
                                <a-input placeholder="参数类型(不写默认为string)" v-model="item.type"></a-input>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="1">
                            <a-button style="margin-top: 4px" @click="removeItem" :data-item="item" :data-index="i"
                                      data-target="headers" type="danger">-
                            </a-button>
                        </a-col>
                    </a-row>


                </a-tab-pane>


                <a-tab-pane key="3">
                  <span slot="tab">
                    请求参数
                </span>
                    <a-alert style="margin-bottom:10px" message="若想模拟请求，此处请填入常量" type="info" close-text="我知道了"/>
                    <a-row>
                        <a-col :span="24">
                            <a-button @click="addItem" data-target="params" type="primary"> +</a-button>
                        </a-col>
                    </a-row>
                    <a-row v-for="(item,i) in formModel.params">


                        <a-col :span="6">
                            <a-form-model-item :label="'参数名称'" :labelCol="labelCol">
                                <a-input placeholder="参数名称" v-model="item.name"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="5">
                            <a-form-model-item label="值类型" :labelCol="labelCol">
                                <a-select v-model="item.paramType"  defaultVlaue="constant" :options="options('paramType')"></a-select>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="6" v-if="item.paramType =='dynamic'">
                            <a-form-model-item :labelCol="labelCol" label="表达式" >
                                <a-input placeholder="请输入表达式" v-model="item.path"></a-input>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="6"  v-if="item.paramType =='constant'" >
                            <a-form-model-item :labelCol="labelCol" label="常量">
                                <a-input placeholder="值" v-model="item.value"></a-input>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="6">
                            <a-form-model-item :labelCol="labelCol" :label="'参数类型'">
                                <a-input placeholder="参数类型(不写默认为string)" v-model="item.type"></a-input>
                            </a-form-model-item>
                        </a-col>



                        <a-col :span="1">
                            <a-button style="margin-top: 4px" @click="removeItem" :data-item="item" :data-index="i"
                                      data-target="params" type="danger">-
                            </a-button>
                        </a-col>
                    </a-row>
                </a-tab-pane>


                <a-tab-pane key="4">
                  <span slot="tab">
                    响应报文
                </span>
                    <a-alert style="margin-bottom:10px" message="目标接口目前仅支持内部接口//todo 外部接口模拟" type="info"
                             close-text="我知道了"/>
                    <a-row>
                        <a-textarea @change="jsonFieldsStrChanged" v-model="fieldsJsonStr" style="height: 200px"
                                    placeholder="将响应报文贴到此处，可以自动解析字段">
                        </a-textarea>
                    </a-row>



                    <a-row style="margin-top: 10px">
                        <a-button @click="simulateRequest" type="primary">模拟请求</a-button>
                    </a-row>


                    <json-viewer
                            style="margin-top: 10px"
                            :value="fieldsJsonForPerView"
                            :expand-depth="5"
                            copyable
                            boxed
                            sort
                            editable
                    ></json-viewer>
                    <a-row style="margin-top: 10px">
                        <a-col :span="24">
                            <a-button @click="addItem" data-target="fields" type="primary">+</a-button>
                        </a-col>
                    </a-row>
                    <a-row v-for="(item,i) in formModel.fields" :key="i">
                        <a-col :span="5">

                        <a-form-model-item label="作用域" :labelCol="labelCol">
                            <a-select v-model="item.activedScope"  defaultVlaue="private" :options="options('ActivedScopes')"></a-select>
                        </a-form-model-item>
                        </a-col>
                        <a-col :span="5">

                            <a-form-model-item :label="'参数名称'" :labelCol="labelCol">
                                <a-input placeholder="参数名称" v-model="item.name"></a-input>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="7">
                            <a-form-model-item :labelCol="labelCol" :label="'表达式'">
                                <a-input placeholder="表达式$.name" v-model="item.path"></a-input>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="5">
                            <a-form-model-item :labelCol="labelCol" :label="'参数类型'">
                                <a-input placeholder="参数类型(不写默认为string)" v-model="item.type"></a-input>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="1">
                            <a-button style="margin-top: 4px" @click="removeItem" :data-item="item" :data-index="i"
                                      data-target="fields" type="danger">-
                            </a-button>
                        </a-col>
                    </a-row>
                </a-tab-pane>
            </a-tabs>
        </a-form-model>
        <div class="modal-footer">
            <a-button type="danger" @click="cancle">取消</a-button>
            <a-button type="primary" @click="onSubmit">保存</a-button>

        </div>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";
    import {message} from "ant-design-vue";
    import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
    import sysInterfaceApi from "../../../shared/service-proxies/sysInterface/services/sys-interface-proxies";
    import Bus from "@/shared/bus/bus";
    import objectHelper from "../../../shared/helpers/objectHelper";

    export default {
        name: "sys-interface-form",
        mixins: [AppComponentBase, ModalComponentBase],
        data() {
            return {
                fieldsJsonStr: "",
                fieldsJson: {},
                labelCol: {
                    span: 6, offset: 0
                },
                interfaceTypes: [],
                requestMethods: [],
                curInternalInterfaceTree: [],
                internalServices: [],
                fieldMaps: {
                    requestMethods: {
                        value: 'id',
                        label: 'itemName',
                    }
                },
                form: {
                    layout: 'horizontal',
                },
                mode: 'create',
                curDataItemTree: [],
                spinning: false,
                // 表单
                formLayout: "horizontal",
                // 选中的权限过滤
                selectedPermission: [],
                formModel: {
                    id: '',
                    name: '',
                    type: 'internal',
                    method: 'GET',
                    path: '',
                    params: [{name: '', value: '', type: ''}],
                    fields: [{name: '', path: '', type: ''}],
                    headers: [{name: '', value: '', type: ''}],
                    exampleResponse: '',
                },
                validModel: {
                    method:
                        [
                            {required: true, message: '请输入接口镜像名称!'}
                        ],
                    name: [
                        {required: true, message: '请输入接口镜像名称!'}
                    ],
                    type: [
                        {required: true, message: '请选择接口类型!'}
                    ],
                    path: [
                        {required: true, message: '请填写接口地址!'}
                    ],
                },
            };
        },

        components: {},
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            //获取系统中的所有内部接口
            sysInterfaceApi.GetInternalServices().then((internalServices) => {
                this.internalServices = internalServices
            })

        },
        computed: {
            fieldsJsonForPerView() {

                try {
                    return JSON.parse(this.fieldsJsonStr)
                } catch (ex) {
                }
            },
            formItemLayout() {
                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        labelCol: {span: 4},
                        wrapperCol: {span: 14},
                    }
                    : {};
            },
            buttonItemLayout() {
                const {layout} = this.form;
                return layout === 'horizontal'
                    ? {
                        wrapperCol: {span: 14, offset: 4},
                    }
                    : {};
            },
        },
        methods: {
            jsonFieldsStrChanged() {
                console.log('jsonFieldsStrChanged')
                let temp = {
                    name: '',
                    path: '',
                    value: '',
                    activedScope:'',
                    type: ''
                }
                let fieldJsonList = []
                try {
                    let fieldJson = JSON.parse(this.fieldsJsonStr)
                    for (let item in fieldJson) {
                        let fieldPath =  item
                        fieldJsonList.push({
                            name: item,
                            path: fieldPath,
                            activedScope:'private',
                            value: "",
                            type: objectHelper.judgeType( fieldJson[item])
                        })
                        this.info(item)
                        this.info(objectHelper.judgeType(fieldJson[item]))
                        if ( objectHelper.judgeType( fieldJson[item]) == "object"  || objectHelper.judgeType( fieldJson[item]) == "array" ) {

                            let getFieldList = function (path, object, list) {

                                if ( objectHelper.judgeType(object) == "array"){
                                    if (object.length> 0){
                                        //数据类型 以第一个元素作为示例对象生成表达式
                                        let  exampleObject = object[0]
                                        path  += '[n]'
                                        list.push({
                                            name: path,
                                            path: path,
                                            value: "",
                                            activedScope:'private',
                                            type: objectHelper.judgeType(exampleObject)
                                        })

                                        if(objectHelper.judgeType( exampleObject ) =="object"
                                            || objectHelper.judgeType( exampleObject ) =="array"
                                        )
                                        {
                                             return getFieldList(path,exampleObject,list)
                                        }
                                    }
                                }




                                for (let i in object) {
                                    let curFieldPath = path + '.' + i
                                    list.push({
                                        name: i,
                                        path: curFieldPath,
                                        value: "",
                                        activedScope:'private',
                                        type: objectHelper.judgeType( object[i])
                                    })
                                    if ( objectHelper.judgeType(   object[i] )== "object") {
                                        return getFieldList(curFieldPath, object[i], list)
                                    }

                                }
                            }
                            getFieldList(fieldPath, fieldJson[item], fieldJsonList)
                        }


                    }
                    console.log(fieldJsonList)
                    this.formModel.fields = fieldJsonList
                } catch (ex) {
                    console.log(ex)
                    message.error('解析响应报文失败！')
                }
            },

            //模拟请求
            simulateRequest(e) {
                console.log(this.formModel.path)
                sysInterfaceApi.simulateRequest(
                    this.formModel.path, this.formModel.type, this.formModel.method, this.formModel.headers, this.formModel.params
                ).then((resp) => {
                    this.formModel.exampleResponse = this.fieldsJsonStr = JSON.stringify(resp)
                    this.jsonFieldsStrChanged()
                    //         console.log(resp)
                })
            },
            removeItem(e) {
                let target = e.target.dataset.target
                let index = e.target.dataset.index
                let temp = this.formModel[target]
                temp = temp.filter((value, i) => {
                    return i != index;
                });
                this.formModel[target] = temp
            },
            addItem(e) {
                console.log(e)
                let target = e.target.dataset.target
                this.formModel[target].push({
                    name: '', value: '', type: '',paramType:"constant",path:'global.'
                })
            },
            cancle() {
                this.close()
            },
            onSubmit() {
                this.$refs.ruleForm.validate(valid => {
                    console.log(valid)
                    if (!valid) {
                        message.error('表单验证失败，请检查后重新提交！')
                        return false;
                    }
                    sysInterfaceApi.save(this.formModel).then((data) => {
                        this.$notification["success"]({
                            message: "保存成功",
                        });

                        this.close()
                    }).catch((error) => {
                        console.log('-----oh 我的玉帝 报错了---------')
                        console.log(error)
                    })
                });

            },
            updateSpinning(newV) {
                if (newV === "0") {
                    this.spinning = false;
                } else {
                    this.spinning = true;
                }
            },
        },
    };
</script>

<style scoped lang="less">
    .modal-header {
        margin-bottom: 0;

        .anticon {
            margin-right: 10px;
        }
    }

    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>
